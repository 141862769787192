import React from "react"
import Layout from "../components/Layout"
import { Link } from 'gatsby-plugin-intl'

// markup
const NotFoundPage = () => {
  return (
    <Layout seo={{title: '404', description: ''}}>
      <h1>404</h1>
      <Link to='/'>Home</Link>
    </Layout>
  )
}

export default NotFoundPage
